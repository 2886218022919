<template>
  <nav class="navbar navbar-expand container-fluid navbar-light bg-white box-shadow border-bottom" style="padding-left: 10px; padding-right: 10px;">
    <!-- <button class="btn btn-primary" id="menu-toggle">Toggle Menu</button>
     <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
       <span class="navbar-toggler-icon"></span>
     </button> -->
    <div class="col-sm-10  d-none d-lg-block signoutRight">
      <a  @click="GoOut" style="text-decoration: none; cursor: pointer;">
        <font  class="mainNavbarFontStyle" style="padding-left: 0px;" face="Times New Roman">
          <font color="#00979e">Rx<font color="#ff8000">Ix</font></font>
        </font>
      </a>
    </div>
  
    <div class="col-sm-5  d-block d-lg-none signoutRight">
      <a  @click="GoOut" style="text-decoration: none">
        <font  class="mainNavbarFontStyle" style="padding-left: 0px;" face="Times New Roman">
          <font color="#00979e">Rx<font color="#ff8000">Ix</font></font>
        </font>
      </a>
    </div>
    <div class=" col-sm-5 col-6 mobileNav  d-block d-lg-none searchInputBox" v-if="role=='hcp' || role=='user'">
      <input class="form-control " type="search" name="search" placeholder="Search">
    </div>

    <div class="col-sm-1  d-none d-lg-block" v-if="role == 'hcp'">
      <router-link to="" class="common-icon-desktop"  aria-current="page" @click="doctorDesktop()" ><font-awesome-icon :icon="['fas', 'user-circle']" /> </router-link>
    </div>
    <div class="col-sm-1  col-1 mobileNav d-block d-lg-none"  v-if="role == 'hcp'">
      <router-link to="" class="common-icon-desktop"  aria-current="page" @click="doctorMobile()" ><font-awesome-icon :icon="['fas', 'user-circle']" /> </router-link>
    </div>
    <div class="col-sm-1  d-none d-lg-block"  v-if="role == 'user'">
      <router-link to="" class="common-icon-desktop"  aria-current="page" @click="userDesktop()" ><font-awesome-icon :icon="['fas', 'user-circle']" /> </router-link>
    </div>
    <div class="col-sm-1 mobileNav d-block d-lg-none"  v-if="role == 'user'">
      <router-link to="" class="common-icon-desktop"  aria-current="page" @click="userMobile()" ><font-awesome-icon :icon="['fas', 'user-circle']" /> </router-link>
    </div>
    <div class="col-sm-1  d-none d-lg-block"  v-if="role == 'clinician-others'">
      <router-link to="" class="common-icon-desktop"  aria-current="page" @click="clinicianOthersDesktop()" ><font-awesome-icon :icon="['fas', 'user-circle']" /> </router-link>
    </div>
    <div class="col-sm-1 mobileNav d-block d-lg-none"  v-if="role == 'clinician-others'">
      <router-link to="" class="common-icon-desktop"  aria-current="page" @click="clinicianOthersMobile()" ><font-awesome-icon :icon="['fas', 'user-circle']" /> </router-link>
    </div>
    <div class="col-sm-1 col-1 mobileNav " v-if="role == 'hcp'">
      <router-link to=""  aria-current="page" class="common-icon-desktop" @click="signOutDoctor()" ><font-awesome-icon :icon="['fas', 'sign-out-alt']" /> </router-link>
    </div>
    <div class="col-sm-1 col-1 mobileNav " v-if="role == 'user'">
      <router-link to="" aria-current="page" class="common-icon-desktop"  @click="signOutPublic()"><font-awesome-icon :icon="['fas', 'sign-out-alt']" /> </router-link>
    </div>
    <div class="col-sm-1 col-1 mobileNav " v-if="role == 'clinician-others'">
      <router-link to="" aria-current="page" class="common-icon-desktop"  @click="signOutClinicianOthers()"><font-awesome-icon :icon="['fas', 'sign-out-alt']" /> </router-link>
    </div>
    <div class="col-sm-2  d-none d-lg-block" v-if="role == 'admin'">
       <router-link to=""   aria-current="page" class="common-icon-desktop"  @click="signOutAdmin()" ><font-awesome-icon :icon="['fas', 'sign-out-alt']"  /> </router-link>
     </div>

    <!-- <div class="col-sm-8 col-xs-8">
     <div class="navbar-collapse ml-mobile">
       <ul class="navbar-nav ms-auto mt-2">
         
         <li class="nav-item dropdown d-none d-md-block">
           <a class="nav-link dropdown-toggle text-color" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
             Hi Bavadharani
           </a>
           <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
             <a class="dropdown-item" href="/index">Logout</a>
           </div>
         </li>
         <li class="nav-item  d-none d-md-block">
           <router-link :to="'/'+role+'/settings'" class="nav-link text-color"><font-awesome-icon :icon="['fas','cog']" /></router-link>
         </li>
         <li class="nav-item d-block d-md-none">
           <router-link :to="'/'+role+'/message'" class="nav-link text-color mt-1" style="font-size:28px"><font-awesome-icon :icon="['fas','envelope']" /></router-link>
         </li>
         <li class="nav-item  d-block d-md-none">
           <router-link :to="'/'+role+'/settings'" title="Team" class="nav-link text-color mt-1"  style="font-size:28px"><font-awesome-icon :icon="['fas','user-cog']" /></router-link>
         </li>
         <li class="nav-item  d-block d-md-none">
           <router-link :to="'/'+role+'/invite'" class="nav-link text-color mt-1"  style="font-size:28px"><font-awesome-icon :icon="['fas','user-plus']" /></router-link>
         </li>
       </ul>
     </div>
      </div> -->
  </nav>
</template>
<script>
import axios from 'axios';
export default {
  name:"DoctorHeader",
  props:["role"],
  data(){
    return{
      active:false,

    }
  },
  methods:{
    GoOut(){
      if(this.role=='hcp'){
        window.scrollTo(0, 0);
        this.$router.push(`/doctor/index`);
      }
      else if(this.role=='user'){
        window.scrollTo(0, 0);
        this.$router.push(`/user/index`);
      }
      else if(this.role=='admin'){
        window.scrollTo(0, 0);
        this.$router.push(`/admin/index`);
      }
      else{         
        window.scrollTo(0, 0);    
        this.$router.push(`/`);
      }
    },
    sidebarToggle(){
      const body = document.querySelector("body")
      this.active = !this.active
      this.active ? body.classList.add("toggled") : body.classList.remove("toggled")
    },
    doctorDesktop() {
      const query = this.$route.query;
      window.scrollTo(0, 0);
      this.$router.push({path: `/doctor/settings`, query });
    },
    doctorMobile() {
      const query = this.$route.query;
      window.scrollTo(0, 0);
      this.$router.push({ path: `/doctor/mobile-settings`, query });
    },
    userDesktop(){
      const query = this.$route.query;
      window.scrollTo(0, 0);
      this.$router.push({path: `/user/settings`, query });
    },
    userMobile(){
      const query = this.$route.query;
      window.scrollTo(0, 0);
      this.$router.push({path: `/user/mobile-setting`, query });
    },
    clinicianOthersDesktop(){
      const query = this.$route.query;
      window.scrollTo(0, 0);
      this.$router.push({path: `/clinicianothers/settings`, query });
    },
    clinicianOthersMobile(){
      const query = this.$route.query;
      window.scrollTo(0, 0);
      this.$router.push({path: `/user/mobile-setting`, query });
    },
    signOutDoctor(){
    this.DoctorUserID = localStorage.getItem('id')
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      let updatePayload={
        is_doctorsession :false,
        doctorunique_id:''
        }
        axios
        .put(`${BASE_API_URL}/hcps/${this.DoctorUserID}/update`, updatePayload, )
        .then((response)=>{
          if(response.data){
            localStorage.removeItem("id")
            localStorage.removeItem("doctor-uniqueId")
            window.location.href = "/"; 
          }
        })
    },
    signOutPublic() {
    this.PublicID = localStorage.getItem('publicSignUpData')
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      let updatePayload={
        is_publicsession :false,
        publicunique_id:''
        }
        axios
        .put(`${BASE_API_URL}/public-user/${this.PublicID}/update`, updatePayload, )
        .then((response)=>{
          if(response.data){
      localStorage.removeItem("publicSignUpData")
      localStorage.removeItem("public-uniqueId")
      window.location.href = "/";
    }
        })
    },
    signOutClinicianOthers() {
    this.clinicianOthersID = localStorage.getItem('clinician-others-id')
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      let updatePayload={
        is_clinicianothers_session :false,
        }
        axios
        .put(`${BASE_API_URL}/clinician-others/${this.clinicianOthersID}/update`, updatePayload, )
        .then((response)=>{
          if(response.data){
      localStorage.removeItem("clinician-others-id")
      window.location.href = "/";
    }
        })
    },
    signOutAdmin(){
      this.AdminID = localStorage.getItem('AdminsignIn')
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      let updatePayload={
        is_adminsession :false,
        adminunique_id:''
        }
        axios
        .put(`${BASE_API_URL}/admin/${this.AdminID}/update`, updatePayload, )
        .then((response)=>{
          if(response.data){
            localStorage.removeItem("AdminsignIn")
            localStorage.removeItem("admin-uniqueId")
            window.location.href = "/"; 
          }})
    }

  },
}
</script>
<style>
  .navbar-expand-lg {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%), 0 2px 4px 0 rgb(0 0 0 / 12%)
  }
.common-icon-desktop{
  font-size: 40px;
  color: #000;
  float: right;
}
.mainNavbarFontStyle{
  font-size:50px;
}
@media screen and (max-width:992px) {
  .navbar{
    padding-top:0rem;
    padding-bottom:0rem;
  }
  .common-icon-desktop{
    font-size: 30px;
  }
  /* .mainNavbarFontStyle{
font-size:40px;
} */
}
@media screen and (max-width:576px){
  .mainNavbarFontStyle{
    font-size:35px;
  }

}
@media screen and (max-width:575px){
  .signoutRight{
    width: 100%;
  }
}
@media screen and (max-width:405px) {
 .mobileNav{
  margin: 0.5rem;
 }
}
</style>