<template>
<div class="footer fixed-bottom">
  <nav v-if="role == 'admin'" class="nav nav-fill bottom-nav">
  <router-link to="/admin/index" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','home']" /><p class="bottom-title">Home</p></router-link>
  <router-link to="/admin/taskmanager" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','stethoscope']" /><p class="bottom-title">Task</p></router-link>
  <router-link to="/admin/financemanager" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','book-medical']" /><p class="bottom-title">Finance</p></router-link>
  <router-link to="/admin/assetmanager" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','book-medical']" /><p class="bottom-title">Assets</p></router-link>
   <router-link to="/admin/invite" class="nav-link bottom-link"><font-awesome-icon :icon="['fas','user-plus']" /><p class="bottom-title">Invite</p></router-link>
  </nav>
  <!-- end admin -->
   <nav v-else class="d-none nav nav-fill bottom-nav">
  <router-link to="/index" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','home']" /><p class="bottom-title">Home</p></router-link>
  <!-- <router-link to="/doctors" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','stethoscope']" /><p class="bottom-title">Doctors</p></router-link> -->
  <router-link to="/user/request" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','stethoscope']" /><p class="bottom-title">Sign up / sign in</p></router-link>
  <router-link to="/aboutus" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','book-medical']" /><p class="bottom-title">About us</p></router-link>
  <router-link to="/contactus" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','book-medical']" /><p class="bottom-title">Contact us</p></router-link>
  </nav>
  <!-- end user -->
</div>
</template>
<script>
export default {
    props: ["role"],
    data() {
    return {

    }},
    created: function () {
    // this.statusTrue();
  },
    methods: {
  currentPath(routePath) {
      this.$router.push(routePath);
    },
    isRouteActive(routePath) {
      return this.$route.path === routePath;
    },
    }
}
</script>
<style>
.footer-image-aftersignup{
  margin: 0px !important;
  color: #000 !important;
  cursor: pointer;
}
.tab-activated {
  background-color:#00979e;
  color: #fff !important;
  margin: 0px;
}
</style>